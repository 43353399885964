<template>
  <div class="w1314 mt91">
    <section id="gameFrame"></section>
    <section id="gameWrap">
      <div id="gameControl">
        <div class="gcwrap gcwrapA">
          <div class="gchead">
            <h2 class="w50w">일반볼 합계</h2>
            <h2 class="w50w">파워볼</h2>
          </div>
          <div class="gcbody">
            <div class="w50w fxCo gap5">
              <ul>
                <li><em class="bl">홀</em>1.95</li>
                <li><em class="rd">짝</em>1.95</li>
              </ul>
              <ul>
                <li><span>3게임<em class="bl">홀</em>1.5오버</span>1.95</li>
                <li><span>3게임<em class="rd">짝</em>1.5오버</span>1.95</li>
              </ul>
              <ul>
                <li><span>3게임<em class="bl">홀</em>2.0오버</span>4.75</li>
                <li><span>3게임<em class="rd">짝</em>2.0오버</span>4.75</li>
              </ul>
            </div>
            <div class="w50w fxCo gap5">
              <ul>
                <li><em class="bl">홀</em>1.95</li>
                <li><em class="rd">짝</em>1.95</li>
              </ul>
              <ul>
                <li><span>3게임<em class="bl">홀</em>1.5오버</span>1.95</li>
                <li><span>3게임<em class="rd">짝</em>1.5오버</span>1.95</li>
              </ul>
              <ul>
                <li><span>3게임<em class="bl">홀</em>2.0오버</span>4.75</li>
                <li><span>3게임<em class="rd">짝</em>2.0오버</span>4.75</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gcwrap gcwrapB">
          <div class="gchead">
            <h2>일반볼 구간</h2>
          </div>
          <div class="gcbody">
            <div class="w100w fxCo gap5">
              <ul>
                <li><span>소(15-64)</span>2.95</li>
                <li><span>중(65-80)</span>2.65</li>
                <li><span>대(81-130)</span>2.95</li>
              </ul>
              <ul>
                <li><span>A(15-35)</span>82.95</li>
                <li><span>B(36-49)</span>12.60</li>
                <li><span>C(50-57)</span>9.20</li>
                <li><span>D(58-65)</span>6.25</li>
                <li><span>E(66-78)</span>3.20</li>
                <li><span>F(79-130)</span>2.60</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gcwrap gcwrapC">
          <div class="gchead">
            <h2>파워볼 구간</h2>
          </div>
          <div class="gcbody">
            <div class="w100w fxCo gap5">
              <ul>
                <li><span>0</span>9.50</li>
                <li><span>1</span>9.50</li>
                <li><span>2</span>9.50</li>
                <li><span>3</span>9.50</li>
                <li><span>4</span>9.50</li>
                <li><span>5</span>9.50</li>
                <li><span>6</span>9.50</li>
                <li><span>7</span>9.50</li>
                <li><span>8</span>9.50</li>
                <li><span>9</span>9.50</li>
              </ul>
              <ul>
                <li><span>A(0-2)</span>3.15</li>
                <li><span>B(3-4)</span>4.75</li>
                <li><span>C(5-6)</span>4.75</li>
                <li><span>A(7-9)</span>3.15</li>
              </ul>
              <ul>
                <li><span>4.5언더<em class="bl">홀수</em></span>4.75</li>
                <li><span>4.5언더<em class="rd">짝수</em></span>3.15</li>
                <li><span>4.5오버<em class="bl">홀수</em></span>3.15</li>
                <li><span>4.5오버<em class="rd">짝수</em></span>4.75</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="gameInfo">
        <div class="gihead">
          <h2>BETTING SLIP</h2>
        </div>
        <div class="gibody">
          <ul>
            <li>
              <span>회차</span>
              <span><em>1007700</em> [<em>140회차</em>]</span>
            </li>
            <li>
              <span>베팅</span>
            </li>
            <li>
              <span>배당율</span>
            </li>
            <li>
              <span>예상배당금</span>
            </li>
            <li>
              <span>보유잔액</span>
              <span><em>0</em>원</span>
            </li>
            <li>
              <span>베팅금액</span>
              <span><input>원</span>
            </li>
            <li>
              <span>베팅한도</span>
              <span>20,000원</span>
            </li>
          </ul>
          <ul class="moneybtnWrap">
            <li>1만원</li>
            <li>10만원</li>
            <li>100만원</li>
            <li class="pointColorY">초기화</li>
            <li>5만원</li>
            <li>50만원</li>
            <li>500만원</li>
            <li class="pointColorY">최대</li>
          </ul>
          <button class="betBtn">베팅하기</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'ingame2'
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.fxCo {display: flex; flex-direction: column;}
.gap5 {gap:5px}
#gameFrame{
  background: #3b3b3b; min-height: 540px; margin-bottom: 20px;
}
#gameWrap{
  margin-bottom: 20px; display: flex; gap: 20px;
}
#gameControl{
  display: flex; flex-direction: column; gap: 20px; width: 70%;
}
#gameInfo {
  display: flex; flex-direction: column; width: calc( 30% - 20px); background: #333;
}
.gcwrap{
  background: #333;
}
.gchead {display: flex; width: 100%;}
.gchead h2 {color: #fff; font-size: 20px; text-align: center; width: 100%; padding: 20px 0;}
.gcbody {width: 100%; display: flex; }
.gcbody > div { padding: 10px; }
.gcbody ul {display: flex; justify-content: center; gap: 5px;}
.gcwrap ul li {width: 100%;text-align: center; background: linear-gradient(to bottom, #535353, #3d3d3d); padding: 15px 0; border: 1px solid #333; border-radius: 9px; color: #fff; font-size: 14px; display: flex; flex-direction: column; gap: 5px; cursor: pointer; }

.gcwrap ul li span {margin-right: 3px; font-size: 18px;}
.gcwrap ul li em {margin: 0 3px;}
.gcwrapA .gchead{}
.gcwrapB .gchead{}
.gcwrapC .gchead{}

.gihead{padding: 15px 10px; color: #f39c19; background: #000;}
.gibody{padding: 15px 10px; color: #fff;}
.gibody ul:nth-child(1) {display: flex; flex-direction: column; gap: 15px;}
.gibody ul.moneybtnWrap {display: flex; flex-wrap: wrap; margin-top: 20px; gap: 10px;}
.gibody ul li {display: flex; justify-content: space-between; padding-left: 5px;}
.gibody ul li span:nth-child(1){position: relative; color: #c9c9c9;}
.gibody ul li span:nth-child(1)::before {position: absolute; content: ""; left: -7px; top: 50%; transform: translateY(-50%); width: 3px; height: 3px; background: #348ca7; border-radius: 3px; }
.gibody ul li span > input {background: none; border: 1px solid #797979; border-radius: 5px; margin-right: 3px;}
.gibody ul.moneybtnWrap li {width: calc(25% - 7.5px); padding-left: 0; box-sizing: border-box; display: flex; justify-content: center; align-items: center; height: 40px; border: 1px solid #999999; cursor: pointer; }
.gibody ul.moneybtnWrap li.pointColorY {color: #f39c19}
.betBtn {background: linear-gradient(to bottom, #d30606, #830000); padding: 15px 0; width: 100%; border: 0; color: #fff; font-size: 20px; margin-top: 20px; cursor: pointer; }
</style>
